import lt from './lt';
import en from './en';
import pl from './pl';

export const translationsList = {
  lt,
  en,
  pl,
};

export const languageOptions = [
  { id: 'lt', text: 'Lietuvių' },
  { id: 'en', text: 'English' },
  { id: 'pl', text: 'Polski' },
];
