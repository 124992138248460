// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-game-2048-js": () => import("./../src/pages/game-2048.js" /* webpackChunkName: "component---src-pages-game-2048-js" */),
  "component---src-pages-students-registration-js": () => import("./../src/pages/students-registration.js" /* webpackChunkName: "component---src-pages-students-registration-js" */),
  "component---src-templates-academy-kids-index-jsx": () => import("./../src/templates/academyKids/index.jsx" /* webpackChunkName: "component---src-templates-academy-kids-index-jsx" */),
  "component---src-templates-academy-kids-mentors-index-jsx": () => import("./../src/templates/academyKidsMentors/index.jsx" /* webpackChunkName: "component---src-templates-academy-kids-mentors-index-jsx" */),
  "component---src-templates-academy-professionals-index-jsx": () => import("./../src/templates/academyProfessionals/index.jsx" /* webpackChunkName: "component---src-templates-academy-professionals-index-jsx" */),
  "component---src-templates-academy-students-landing-index-jsx": () => import("./../src/templates/academyStudentsLanding/index.jsx" /* webpackChunkName: "component---src-templates-academy-students-landing-index-jsx" */),
  "component---src-templates-academy-students-single-index-jsx": () => import("./../src/templates/academyStudentsSingle/index.jsx" /* webpackChunkName: "component---src-templates-academy-students-single-index-jsx" */),
  "component---src-templates-automation-hackathon-index-jsx": () => import("./../src/templates/automationHackathon/index.jsx" /* webpackChunkName: "component---src-templates-automation-hackathon-index-jsx" */),
  "component---src-templates-book-download-page-index-jsx": () => import("./../src/templates/bookDownloadPage/index.jsx" /* webpackChunkName: "component---src-templates-book-download-page-index-jsx" */),
  "component---src-templates-contact-author-index-jsx": () => import("./../src/templates/contactAuthor/index.jsx" /* webpackChunkName: "component---src-templates-contact-author-index-jsx" */),
  "component---src-templates-front-page-index-jsx": () => import("./../src/templates/frontPage/index.jsx" /* webpackChunkName: "component---src-templates-front-page-index-jsx" */),
  "component---src-templates-privacy-policy-index-jsx": () => import("./../src/templates/privacyPolicy/index.jsx" /* webpackChunkName: "component---src-templates-privacy-policy-index-jsx" */),
  "component---src-templates-secret-source-index-jsx": () => import("./../src/templates/secretSource/index.jsx" /* webpackChunkName: "component---src-templates-secret-source-index-jsx" */),
  "component---src-templates-secret-source-pages-index-jsx": () => import("./../src/templates/secretSourcePages/index.jsx" /* webpackChunkName: "component---src-templates-secret-source-pages-index-jsx" */),
  "component---src-templates-thought-leaders-index-jsx": () => import("./../src/templates/thoughtLeaders/index.jsx" /* webpackChunkName: "component---src-templates-thought-leaders-index-jsx" */)
}

