import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { languageOptions, translationsList } from 'lang';

export const GlobalStateContext = createContext({
  language: languageOptions[0],
  dictionary: translationsList[languageOptions[0].id],
});

const GlobalContextProvider = ({ children }) => {
  const languageContext = useContext(GlobalStateContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage) => {
      setLanguage(selectedLanguage);
      setDictionary(translationsList[selectedLanguage.id]);
    },
  };

  return (
    <GlobalStateContext.Provider value={provider}>
      {children}
    </GlobalStateContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalContextProvider;
